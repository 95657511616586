<template>
  <div class="cart_block" v-if="itemsArray.length > 0">
    <h4 class="h2-super_title txt-bold">
      推薦商品 <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
    </h4>

    <div class="recommend_area">
      <swiper
      :options="swiperOption"
      class="itembox swiper-container"
      >
        <swiper-slide v-for="(product, i) in itemsArray"
          :key="`remommend_${i}`"
          class="item">
          <div class="topbox">
            <div class="picbox">
              <figure
                :style="`background-image: url(${product.cover});`"></figure>
            </div>
            <div class="pro_name">
              <div class="tag small">{{product.category}}</div>
              <h6 class="txt-bold">{{product.product_name}}</h6>
              <small class="txt-greenlake" v-if="product.flow">流量: {{product.flow}}</small>
            </div>
          </div>
          <div class="bottombox">
            <!-- <p class="tag-discount txt-white">
              輸入 wiho359 可享有每日$359優惠價
              <img src="@/assets/img/icon/icon_exclamation-w.svg" style="width: .65em;" alt="">
            </p> -->
            <p class="prize">
              <span class="txt-orange h5">優惠價 ${{product.sell_price}}</span>
              <span class="small">/{{product.unit}}</span>
              <span class="txt-line-through small" v-if="product.price">售價 ${{product.price}}</span>
            </p>
            <div class="datePicker">
              <template v-if="product.date">
                <p>出國時間:</p>
                <date-picker
                  v-model="product.date"
                  class="datePicker"
                  type="date"
                  range
                  :disabledDate="date => date <= new Date().setDate(new Date().getDate() - 1)"
                  placeholder="出國日 － 回國日">
                </date-picker>
              </template>
              <template v-else>
                <template v-if="product.colors.length > 0">
                  <p>顏色</p>
                  <select v-model="product.color_id" class="small">
                    <option
                      v-for="(color, i) in product.colors"
                      :key="`color_${i}`" :value="color.id">
                      {{color.color_name}}
                    </option>
                  </select>
                </template>
              </template>
              <template v-if="product.category === 'eSIM'">
                <template v-if="brandOption.length > 0">
                  <p>機型</p>
                  <select
                    @input="selectBrand($event, i)"
                    :value="product.brand" class="small">
                      <template v-for="(brand, i) in brandOption">
                        <option v-show="brand.model.length > 0"
                          :key="`brand_${i}`" :value="brand.id">{{brand.name}}</option>
                      </template>
                  </select>
                  <p>機種</p>
                  <select
                      @input="selectModel($event, i)"
                      :value="product.model" class="small">
                      <option
                      v-for="(selectBrand, i) in brandOption[product.brandIndex].model"
                      :key="`color_${i}`" :value="selectBrand.id">{{selectBrand.name}}</option>
                  </select>
                </template>
              </template>
            </div>
          </div>
          <div class="btnbox">
            <div class="number" data-max="10">
              <div class="minus" @click="minus(i)">-</div>
              <input class="count" type="text" :value="product.qty" readonly>
              <div class="plus" @click="plus(i)">+</div>
            </div>
            <button
            @click="addCart(product)"
            type="button" class="btn btn-cyan_line p">加入購物車</button>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
      <!-- <div class="itembox swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide item">
            <div class="topbox">
              <div class="picbox">
                <figure
                  style="background-image: url(@/assets/img/shopping/product_1.png);"></figure>
              </div>
              <div class="pro_name">
                <div class="tag small">wi-fi分享器</div>
                <h6 class="txt-bold">日本藍鑽石</h6>
                <small class="txt-greenlake">流量: 吃到飽</small>
              </div>
            </div>
            <div class="bottombox">
              <p class="tag-discount txt-white">
                輸入 wiho359 可享有每日$359優惠價
                <img src="@/assets/img/icon/icon_exclamation-w.svg" style="width: .65em;" alt="">
              </p>
              <p class="prize">
                <span class="txt-orange h5">優惠價 $359</span>
                <span class="small">/張</span>
                <span class="txt-line-through small">售價 $399</span>
              </p>
            </div>
            <div class="btnbox">
              <div class="number" data-max="10">
                <div class="minus">-</div>
                <input class="count" type="text" value="1" readonly>
                <div class="plus">+</div>
              </div>
              <button type="button" class="btn btn-cyan_line p">加入購物車</button>
            </div>
          </div>
        </div>
      </div> -->
      <div class="arrow">
        <div class="to_left">
          <img src="@/assets/img/icon/icon_next-w.svg" alt="">
        </div>
        <div class="to_right">
          <img src="@/assets/img/icon/icon_next-w.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import requestApi from '@/lib/http/index';

export default {
  name: 'CartRecommend',
  components: {
    DatePicker,
  },
  data() {
    return {
      brandOption: [],
      itemsArray: [],
      swiperOption: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 30,
        allowTouchMove: true,
        navigation: {
          prevEl: '#cart .recommend_area .arrow .to_left',
          nextEl: '#cart .recommend_area .arrow .to_right',
        },
        pagination: {
          el: '#cart .swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1023: {
            slidesPerView: 2.5,
          },
          880: {
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 1.5,
          },
          425: {
            slidesPerView: 1,
          },
        },
      },
    };
  },
  methods: {
    ...mapMutations([
      'setCart',
    ]),
    minus(v) {
      if (this.itemsArray[v].qty !== 1) {
        this.itemsArray[v].qty -= 1;
        // this.itemsArray[v].total = this.getTotal(this.itemsArray[v]);
        // if (this.itemsArray[v].price) {
        //   this.itemsArray[v].originTotal = this.getOriginTotal(this.itemsArray[v]);
        // }
      } else {
        this.itemsArray[v].qty = 1;
      }
    },
    plus(v) {
      this.itemsArray[v].qty += 1;
      // this.itemsArray[v].total = this.getTotal(this.itemsArray[v]);
      // if (this.itemsArray[v].price) {
      //   this.itemsArray[v].originTotal = this.getOriginTotal(this.itemsArray[v]);
      // }
    },
    getCategoryType(category, type) {
      let data;
      switch (category) {
        case 'WIFI分享器':
          data = 1;
          break;
        case 'SIM卡':
          data = 1;
          break;
        case 'eSIM':
          data = 1;
          break;
        default:
          data = 2;
          break;
      }
      switch (type) {
        case '出租品':
          data = 1;
          break;
        default:
          break;
      }
      return data;
    },
    getDays(abroadStart, abroadEnd) {
      const startDay = moment(abroadStart, 'YYYY-MM-DD');
      const endDay = moment(abroadEnd, 'YYYY-MM-DD');
      const days = endDay.diff(startDay, 'days') + 1;
      return days;
    },
    addCart(product) {
      const formDataProcess = new Promise((resolve, reject) => {
        const formData = {
          member_id: this.user.id,
        };
        if ('date' in product) {
          if (product.date.length > 0) {
            if (product.date[0] && product.date[1]) {
              const [abroadStart, abroadEnd] = product.date;
              formData.abroad_start = moment(abroadStart).format('YYYY/MM/DD');
              formData.abroad_end = moment(abroadEnd).format('YYYY/MM/DD');
              formData.days = this.getDays(abroadStart, abroadEnd);
              if (product.type === '出租品') {
                formData.total = product.sell_price * product.qty * formData.days;
              } else {
                formData.total = product.sell_price * product.qty;
              }
            } else {
              reject(new Error('請填寫出國時間'));
            }
          } else {
            reject(new Error('請填寫出國時間'));
          }
        } else if ('color_id' in product) {
          if (product.color_id) {
            formData.color_id = product.color_id;
          }
          formData.total = product.sell_price * product.count;
        }
        if (product.category === 'eSIM') {
          if (product.brand && product.model) {
            formData.brand_id = product.brand;
            formData.mobile_model_id = product.model;
          } else {
            reject(new Error('請填寫機型與機種'));
          }
        }
        formData.product_id = product.id;
        formData.price = product.price;
        formData.sell_price = product.sell_price;
        formData.qty = product.qty;
        resolve(formData);
      });
      formDataProcess.then((form) => {
        const addCart = requestApi('cart.addCart', form);
        addCart.then((result) => {
          if (result.status) {
            // const { cart } = this;
            // this.setCart(cart + 1);
            this.$customSWAL({
              icon: 'success',
              title: 'Success',
              text: '加入購物車成功',
              confirmButtonText: '確定',
              thenFunc: () => {
                this.$router.go();
              },
            });
          } else {
            this.$customSWAL({
              icon: 'error',
              title: '錯誤',
              text: result.message,
              confirmButtonText: '確定',
            });
          }
        });
      }).catch((err) => {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: err.message,
          confirmButtonText: '確定',
        });
      });
    },
    async getBrands() {
      const { status, data } = await requestApi('req.getBrands');
      if (status) {
        this.brandOption = data.map((item, i) => {
          const obj = {
            ...item, model: [],
          };
          this.getMobileModelByBrand(item.id, i);
          return obj;
        });
      }
    },
    async getMobileModelByBrand(id, i) {
      const { status, data } = await requestApi('req.getMobileModelByBrand', { brand_id: id });
      if (status) {
        this.brandOption[i].model = data;
      }
    },
    selectBrand(e, index) {
      this.itemsArray[index].brand = e.target.value;
      this.itemsArray[index].model = null;
      this.brandOption.forEach((item, i) => {
        if (item.id === e.target.value) {
          this.itemsArray[index].brandIndex = i;
          if (this.brandOption[i].model[0]) {
            this.itemsArray[index].model = this.brandOption[i].model[0].id;
          }
        }
      });
    },
    selectModel(e, index) {
      this.itemsArray[index].model = e.target.value;
    },
    async getData() {
      // eslint-disable-next-line
      const member_id = this.user.id;
      await this.getBrands();
      const result = await requestApi('cart.getRecommendProducts', { member_id });
      if (result.status) {
        const arr = [];
        result.data.forEach((element) => {
          const obj = {
            qty: 1,
            ...element,
          };
          if (this.getCategoryType(element.category, element.type) === 1) {
            obj.date = [null, null];
          } else {
            obj.color_id = null;
          }
          if (obj.category === 'eSIM') {
            obj.brand = this.brandOption[0].id;
            obj.brandIndex = 0;
            obj.model = this.brandOption[0].model[0].id;
          }
          arr.push(obj);
        });
        this.itemsArray = arr;
      } else {
        this.itemsArray = [];
      }
    },
  },
  computed: {
    ...mapState([
      'cart',
      'user',
    ]),
  },
  mounted() {
    this.getData();
  },
};
</script>
